import { useEffect } from 'react';

const updateDetails = (event, updateDetail) => {
  window.addEventListener(event, () => {
    const details = document.body.querySelectorAll('details');
    details.forEach((detail) => updateDetail(detail));
  });
};

const useSetPrintListeners = function () {
  useEffect(() => {
    updateDetails('beforeprint', (detail) => detail.setAttribute('open', ''));
    updateDetails('afterprint', (detail) => detail.removeAttribute('open'));
  }, []);
};

export default useSetPrintListeners;
