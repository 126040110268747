import { useEffect, useState } from 'react';
import { loadTranslations } from '@guuru/translation-web';
import { locales } from '@guuru/constants-common';
import { useTranslation } from 'react-i18next';
import { parse } from '@guuru/url-common';
import useCommunitySettings from '../../../hooks/useCommunitySettings';

const canUseLocale = (locale) => (
  locales.includes(locale)
    ? locale
    : undefined
);

const useSetLanguage = function () {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const extractedBrowserLocale = navigator.language.split('-')[0];
  const { defaultLanguage, partnerId } = useCommunitySettings();

  useEffect(() => {
    const { locale: localeParam } = parse(window.location.search);
    const locale = canUseLocale(localeParam);
    const browserLocale = canUseLocale(extractedBrowserLocale);
    const language = locale || localStorage.locale || browserLocale || defaultLanguage || 'en';
    localStorage.locale = language;

    const initTranslations = async () => {
      await i18n.changeLanguage(language);
      await loadTranslations(language, ['pages'], partnerId);
      setLoading(false);
    };

    initTranslations();
  }, [
    defaultLanguage,
    i18n,
    partnerId,
    extractedBrowserLocale,
  ]);
  return { loading };
};

export default useSetLanguage;
