import { useSuspenseQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { parse } from '@guuru/url-common';
import { useMemo } from 'react';
import { fromCents, printMoney } from '@guuru/money-common';
import { useTranslation } from 'react-i18next';
import GET_COMMUNITY_SETTINGS from './queries/getCommunitySettings';

const getPartnerIdPath = () => {
  const { pathname } = window.location;
  const [, partnerId] = pathname.split('/');
  return partnerId;
};

const getPartnerIdParameter = () => {
  const { partnerId } = parse(window.location.search);
  return partnerId;
};

const useGetCurrentPartnerId = () => {
  const { partnerId } = useParams();
  if (partnerId) return partnerId;
  return getPartnerIdParameter() || getPartnerIdPath();
};

const useCommunitySettings = () => {
  const partnerId = useGetCurrentPartnerId();
  const { i18n: { language } } = useTranslation();
  const {
    data: {
      community: { id, settings } = {},
    } = {},
  } = useSuspenseQuery(GET_COMMUNITY_SETTINGS, {
    variables: {
      id: partnerId,
      locale: language,
    },
    skip: !partnerId,
  });

  const { name: partnerName, pricing } = settings || {};
  const isPoints = pricing?.[0]?.currency === 'XXX';

  const { goodAmount, partialAmount } = useMemo(() => (
    (pricing || []).reduce((acc, { amount, currency, type } = {}) => {
      if (!amount) return '?';
      const t = type.includes('good') ? 'goodAmount' : 'partialAmount';
      const value = isPoints
        ? fromCents(amount)
        : printMoney(amount, currency, { isCents: true });
      return { [t]: value, ...acc };
    }, {})
  ), [pricing, isPoints]);

  return {
    id,
    ...settings,
    partnerId,
    partnerName,
    rewards: {
      isPoints,
      goodAmount,
      partialAmount,
    },
  };
};

export default useCommunitySettings;
