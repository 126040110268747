import { gql } from '@apollo/client';

export default gql`
  query GetPersistedCommunitySettings($id: ID!, $locale: String) {
    community(id: $id, locale: $locale) {
      id
      settings {
        name
        pricing {
          id
          type
          currency
          amount
        }
        industry {
          id
        }
        validationCode
        defaultLanguage
        allowTransfer
        hasMultipleTargets
        agentsGuuruHotTransfers
      }
    }
  }
`;
