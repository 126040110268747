import React from 'react';
import { createRoot } from 'react-dom/client';
import { initSentry, Sentry } from '@guuru/sentry-web';
import { gtag } from '@guuru/analytics-web';
import { I18nextProvider } from 'react-i18next';
import { ApolloProvider } from '@apollo/client';
import ThemeProvider from '@guuru/theme-web';
import { colors } from '@guuru/constants-common';
import { client } from '@guuru/graphql-web';
import { i18n } from '@guuru/translation-web';
import { ErrorBoundary } from '@guuru/react-error-pages';
import MainView from '../views/MainView';
import GlobalStyle from './GlobalStyle';
import 'antd/dist/reset.css';

if (process.env.PAGES_GA_MEASUREMENT_ID) {
  gtag.initialize(process.env.PAGES_GA_MEASUREMENT_ID, {
    debug_mode: process.env.ANALYTICS_DEBUG,
  });
  gtag.event('page_view', {
    page_path: window.location.pathname + window.location.search,
  });
}

if (process.env.PAGES_SENTRY_DSN) {
  initSentry(process.env.PAGES_SENTRY_DSN);
}

const PagesApp = Sentry.withProfiler(() => (
  <ApolloProvider client={client}>
    <ThemeProvider theme={{ color: colors.primaryColor }}>
      <I18nextProvider i18n={i18n}>
        <ErrorBoundary>
          <GlobalStyle />
          <MainView />
        </ErrorBoundary>
      </I18nextProvider>
    </ThemeProvider>
  </ApolloProvider>
));

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<PagesApp />);
