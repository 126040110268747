import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    background: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.onSurfaceColor};
  }

  #app,
  .app,
  main {
    height: 100%;
  }
`;

export default GlobalStyle;
